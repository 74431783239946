<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-width-1-1 uk-flex uk-flex-column-sm uk-flex-column-md uk-flex-row uk-flex-between uk-flex-middle"
    >
      <div class="uk-width-1-1 uk-width-auto@l">
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
      <div class="uk-width-1-1 uk-width-auto@l uk-margin-small-top uk-margin-remove-top-lg uk-margin-remove-top-xl">
        <span />
      </div>
    </div>

    <div class="uk-card uk-card-default uk-padding-small uk-margin-top base-card">
      Dashboard
    </div>
  </div>
</template>

<script>
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from "@/utils/constant"

export default {
  components: {},
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  methods: {
  }
}
</script>
